import { Auth } from 'aws-amplify';

const awsmobile = {
  Auth: {
    // REQUIRED - Amazon Cognito Identity Pool ID
    // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',
    // REQUIRED - Amazon Cognito Region
    region: process.env.VUE_APP_AWS_REGION,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.VUE_APP_USER_POOL_ID,
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.VUE_APP_USER_POOL_WEB_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: process.env.VUE_APP_AWS_API_NAME,
        endpoint: process.env.VUE_APP_AWS_API_BASE_URL, // /dev/test
        custom_header: async () => {
          // return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
          return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` };
        },
      },
    ],
  },
};

export default awsmobile;
